<template>
  <div class="blog-post-card">
    <div class="blog-post-card__info">
      <div
        v-if="primaryTag"
        class="blog-post-card__tags"
      >
        <nuxt-link
          :to="
            localePath({
              name: 'category-slug',
              params: {
                slug: primaryTag.slug,
              },
            })
          "
        >
          {{ primaryTag.name }}
        </nuxt-link>
      </div>
      <h2 class="blog-post-card__title">
        <nuxt-link :to="postLink">
          {{ post.title }}
        </nuxt-link>
      </h2>
      <p class="blog-post-card__description">
        {{ post.custom_excerpt || post.excerpt }}
      </p>
      <nuxt-link
        :to="postLink"
        class="blog-post-card__link"
      >
        {{ $t('actions.readMore') }}
      </nuxt-link>
    </div>
    <div class="blog-post-card__image-container">
      <nuxt-link
        :to="postLink"
        :title="post.title"
      >
        <img
          class="blog-post-card__feature-image"
          :src="post.feature_image"
          :alt="post.title"
          loading="lazy"
        >
      </nuxt-link>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
})

const runtimeConfig = useRuntimeConfig()
const localePath = useLocalePath()

const primaryTag = computed(() => {
  let { tags } = props.post

  if (!tags || !tags.length) {
    return null
  }

  tags = tags.filter((tag) => !runtimeConfig.public.locales.includes(tag.slug))

  if (!tags.length) {
    return null
  }

  return tags[0]
})

const postLink = computed(() => {
  return localePath({
    name: 'blog-slug',
    params: {
      slug: props.post.slug,
    },
  })
})
</script>

<style lang="scss" scoped>
.blog-post-card {
  display: grid;
  grid-gap: 2em;
  grid-column: 1/-1;

  @include media-min-size(large) {
    grid-template-columns: repeat(3, 1fr);
    grid-row: 1/2;
  }

  @media only screen and (min-width: $medium-breakpoint) and (max-width: ($large-breakpoint - 1)) {
    grid-template-columns: repeat(2, 1fr);
    grid-row: 1/2;
  }

  @include media-max-size(medium) {
    grid-gap: unset;
    grid-template-columns: repeat(1, 1fr);
    margin: auto;
    width: 21rem;
    min-height: 30rem;
  }

  &__info {
    max-height: 16rem;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  &__image-container {
    @include media-min-size(large) {
      grid-column: 2 / 4;
    }

    @media only screen and (min-width: $medium-breakpoint) and (max-width: ($large-breakpoint - 1)) {
      display: flex;
    }

    @include media-max-size(medium) {
      grid-row: 1;
    }
  }

  &__feature-image {
    border-radius: 0.5rem;
    object-fit: cover;

    @include media-min-size(large) {
      height: 100%;
      width: 100%;
    }

    @include media-max-size(large) {
      height: auto;
      max-width: 100%;
      margin: auto;
    }

    @include media-max-size(medium) {
      height: 15.75rem;
      width: 100%;
      object-fit: cover;
    }
  }

  &__tags {
    @include semi-bold;

    margin: 0.875rem 0 0.25rem 0;
    text-transform: uppercase;

    a {
      color: getColor('primary-500');

      &:hover {
        text-decoration: none;
        color: getColor('highlight-500');
      }
    }
  }

  &__title {
    @include reset-heading();
    @include title-1;

    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 4rem;
    -webkit-line-clamp: 2;
    overflow: hidden;

    a {
      color: getColor('primary-500');

      &:hover {
        text-decoration: none;
        color: getColor('highlight-500');
      }
    }
  }

  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin: 0;
  }

  &__link {
    margin: 1rem 0 0 0;
  }
}
</style>
