<template>
  <div
    class="posts-list"
    :class="{ hasFeatured: featured }"
  >
    <CardFeaturedBlogPost
      v-if="showFeatured && featured"
      :post="featured"
      class="posts-list__post__featured"
    />
    <div
      v-if="showFeatured"
      class="posts-list__after-feature"
    >
      <slot name="after-feature" />
    </div>
    <CardAltBlogPost
      v-for="post in posts"
      :key="post.id"
      class="posts-list__post"
      :post="post"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  showFeatured: {
    type: Boolean,
    default: () => false,
  },

  postList: {
    type: Array,
    default: () => [],
  },

  columns: {
    type: Number,
    default: () => 2,
  },
})

const route = useRoute()
const runtimeConfig = useRuntimeConfig()

const featured = computed(() => {
  return props.showFeatured ? props.postList[0] : null
})

const posts = computed(() => {
  return props.showFeatured ? props.postList.slice(1, props.postList.length) : props.postList
})

useJsonld(getStructuredDataBlog({ postList: props.postList, staticAssetsUrl: runtimeConfig.public.staticAssetsURL, routeUrl: route.fullPath, baseUrl: runtimeConfig.public.baseURL }))
</script>

<style lang="scss" scoped>
.posts-list {
  grid-gap: 2em;
  grid-auto-rows: 32rem;
  display: grid;
  margin: auto;
  max-width: 72.6875rem;

  @include media-max-size(medium) {
    grid-gap: 1em;
  }

  &__after-feature {
    display: flex;
    grid-column: 1/-1;
    grid-gap: 1em;

    @include media-max-size(medium) {
      grid-row: 1;
    }
  }

  &.hasFeatured {
    grid-template-rows: 28rem auto;
    @include media-max-size(medium) {
      grid-template-rows: 3rem auto;
    }
  }

  @include media-min-size(large) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: $medium-breakpoint) and (max-width: ($large-breakpoint - 1)) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-max-size(medium) {
    grid-template-columns: repeat(1, 1fr);
  }

  &__post {
    height: 32rem;
    @include media-max-size(medium) {
      height: 30rem;
      margin: auto;
      width: 21rem;
    }
  }
}
</style>
