<template>
  <div class="blog-post-card">
    <NuxtLink
      :to="postLink"
      :title="post.title"
    >
      <img
        class="blog-post-card__feature-image"
        :src="post.feature_image"
        :alt="post.title"
        loading="lazy"
      >
    </NuxtLink>
    <div
      v-if="primaryTag"
      class="blog-post-card__tags"
    >
      <NuxtLink
        :to="
          localePath({
            name: 'category-slug',
            params: {
              slug: primaryTag.slug,
            },
          })
        "
      >
        {{ primaryTag.name }}
      </NuxtLink>
    </div>
    <h2 class="blog-post-card__title">
      <NuxtLink :to="postLink">
        {{ post.title }}
      </NuxtLink>
    </h2>
    <p class="blog-post-card__description">
      {{ post.custom_excerpt || post.excerpt }}
    </p>
    <NuxtLink
      :to="postLink"
      class="blog-post-card__link"
    >
      {{ $t('actions.readMore') }}
    </NuxtLink>
  </div>
</template>

<script setup>
const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
})

const runtimeConfig = useRuntimeConfig()
const localePath = useLocalePath()

const primaryTag = computed(() => {
  let { tags } = props.post

  if (!tags || !tags.length) {
    return null
  }

  tags = tags.filter((tag) => !runtimeConfig.public.locales.includes(tag.slug))

  if (!tags.length) {
    return null
  }

  return tags[0]
})

const postLink = computed(() => {
  return localePath({
    name: 'blog-slug',
    params: {
      slug: props.post.slug,
    },
  })
})
</script>

<style lang="scss" scoped>
.blog-post-card {
  display: flex;
  flex-direction: column;

  &__feature-image {
    border-radius: 0.5rem;
    height: 15.75rem;
    width: 100%;
    object-fit: cover;
  }

  &__tags {
    @include semi-bold;

    margin: 0.875rem 0 0.25rem 0;
    text-transform: uppercase;

    a {
      color: getColor('primary-400');

      &:hover {
        text-decoration: none;
        color: getColor('highlight-500');
      }
    }
  }

  &__title {
    @include reset-heading();
    @include title-1;

    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 4rem;
    -webkit-line-clamp: 2;
    overflow: hidden;

    a {
      color: getColor('primary-500');

      &:hover {
        text-decoration: none;
        color: getColor('highlight-500');
      }
    }
  }

  &__description {
    color: getColor('primary-400');
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin: 0;
  }

  &__link {
    margin: 1rem 0;
    margin-top: auto;

    @include media-max-size(medium) {
      margin: 1rem 0 0 0;
    }
  }
}
</style>
